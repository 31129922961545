<template>
	<dialog-common ref="dialog" :title="option.title" @ok="confirm">
		<div class="content">
			<input class="input" disabled v-model="value" :placeholder="option.placeholder"/>
		</div>

		<keyboard @clickNum="clickNum" @clickPoint="clickPoint" @clickDelete="clickDelete"></keyboard>

	</dialog-common>
</template>

<script>
	import DialogCommon from "./dialog-common.vue"
	import Keyboard from "./keyboard.vue"
	export default {
		components:{DialogCommon,Keyboard},
		props:["title"],
		data(){
			return {
				value:"",
				option:{
					title:"",
					placeholder:"",
					onConfirm:null
				}
			}
		},
		methods:{
			open(option){
				this.option = option
				this.value=""
				this.$refs.dialog.open()
			},
			close(){
				this.$refs.dialog.close()
			},
			confirm(){
				this.option.onConfirm(this.value)
			},
			clickNum(num){
				this.value+=""+num
			},
			clickPoint(){
				this.value+="."
			},
			clickDelete(){
				if(this.value.length>0){
					this.value = this.value.substr(0,this.value.length-1)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content{
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;

		.input{
			text-align: center;
			width: 100%;
			border: 1px solid #CCC;
			border-radius: 5px;

		}

		.unit{
			color: #999999;
			font-size: 28px;
			padding-left: 20px;
		}
	}


</style>
